<script>
import component from 'base/components/AdminTasks'

component.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--admin-tasks')

  this.afterMounted()
}

export default component
</script>
