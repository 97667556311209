import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  get (store) {
    Vue.$logger.debug('admin-task | Getting all tasks')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/tasks'
    // const ACTION_URL = '/pseudo-api/tasks.json'

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-task | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('tasks_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-task', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  approve (store, id) {
    Vue.$logger.debug('admin-task | Approving task; id = ', id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/task/' + id + '/approve'
    // const ACTION_URL = '/pseudo-api/task.json'

    return axios.patch(ACTION_URL, {}, { headers: store.getters['user/authHeader']() })
    // return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-task | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('task_not_found')
          } else if (error.response.data.code) {
            throw new Error(error.response.data.code)
          } else {
            throw new Error('task_approve_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-task', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  decline (store, id, managerComment) {
    Vue.$logger.debug('admin-task | Declining task; id = ', id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/task/' + id + '/decline'
    // const ACTION_URL = '/pseudo-api/task.json'

    return axios.patch(ACTION_URL, { manager_comment: managerComment }, { headers: store.getters['user/authHeader']() })
    // return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-task | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('task_not_found')
          } else {
            throw new Error('task_decline_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-task', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  review(store, id) {
    Vue.$logger.debug('admin-task | Reviewing task; id = ', id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/task/' + id + '/review'

    return axios.patch(ACTION_URL, {}, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-task | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('task_not_found')
          } else if (error.response.status === 409) {
            throw new Error('task_already_reviewed')
          } else {
            throw new Error('task_review_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-task', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })   
  },
  getById(store, id) {
    Vue.$logger.debug('admin-task | Get task by id; id = ', id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/task/' + id

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-task | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('task_not_found')
          } else {
            throw new Error('get_task_by_id_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-task', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })  
  }
}
