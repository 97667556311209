<template>
  <div class="tasks-filter">
    <div>
      <div class="tasks-filter__container">
        <div class="tasks-filter__field">
          <label>Идентификатор</label>
          &nbsp;
          <input class="form-field form-field--large" type="text" v-model="filters.uid" placeholder="79123456789" />
        </div>
        <div>
          <div class="tasks-filter__field">
            <label>Дата</label>
            &nbsp;
            <div class="tasks-filter__field-dates">
              <datepicker
                input-class="form-field form-field--large"
                calendar-class="form-calendar"
                placeholder="с"
                :language="ru"
                full-month-name
                format="dd.MM.yyyy"
                monday-first
                :value="filters.dateFrom"
                @selected="onDateFromSelected"
              />
              <datepicker
                input-class="form-field form-field--large form-field--full-width"
                calendar-class="form-calendar"
                placeholder="по"
                :language="ru"
                full-month-name
                format="dd.MM.yyyy"
                monday-first
                @selected="onDateToSelected"
                :value="filters.dateTo"
              />
            </div>
          </div>
        </div>
        <div class="tasks-filter__field">
          <label>Ответственный</label>
          &nbsp;
          <input class="form-field form-field--large" type="text" v-model="filters.reviewer" placeholder="79123456789" />
        </div>
        <div class="tasks-filter__field">
          <label>Сортировать по</label>
          <select class="form-field form-field--large" v-model="sort">
            <option :value="null" disabled>Выбрать</option>
            <option v-for="option in sortOptions" :value="option">{{ option.label }}</option>
          </select>
        </div>
        <div class="tasks-filter__field">
          <label>Статус</label>
          <select class="form-field form-field--large" v-model="status">
            <option :value="null" disabled>Выбрать</option>
            <option v-for="option in statusOptions" :value="option">{{ option.label }}</option>
          </select>
        </div>
      </div>
      <div class="tasks-filter__buttons">
        <button type="submit" class="button button--large button--rounded" @click="applyFilters">Применить</button>
        <button type="reset" class="button button--large button--rounded button--transparent" @click="resetFilters">Сбросить</button>
      </div>
    </div>

    <div class="tasks-filter__sort">
      <!-- <div class="tasks-filter__sort-container">
        <button
          class="tasks-filter__sort-item"
          :class="{
            'tasks-filter__sort-item--active': activeSortOption && sort.value === activeSortOption.type,
            'tasks-filter__sort-item--desc': activeSortOption && sort.value === activeSortOption.type && activeOrder === 'desc',
          }"
          :disabled="sort.disabled"
          v-for="(sort, idx) in sortFilters"
          :key="idx"
          @click="setActiveSort(sort)"
        >
          {{ sort.label }}
          <font-awesome-icon icon="arrow-up" />
        </button>
      </div> -->
    </div>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
const sortFilters = [
  {
    label: 'Дата',
    value: 'date',
  },
  {
    label: 'Ответственный',
    value: 'reviewer',
    disabled: true,
  },
]

const filters = {
  uid: '',
  reviewer: '',
  dateFrom: '',
  dateTo: '',
}

const sort = {
  value: {
    sort: '',
    order: '',
  },
}
export default {
  components: { Datepicker },
  data: () => ({
    // sortFilters,
    ru,
    filters: Object.assign({}, filters),
    sort: null,
    sortOptions: [
      {
        type: 'date',
        label: 'Дате изменения - сначала старые',
        value: {
          sort: 'updated_at',
          order: 'asc',
        },
      },
      {
        type: 'date',
        label: 'Дате изменения - сначала новые',
        value: {
          sort: 'updated_at',
          order: 'desc',
        },
      },
      {
        type: 'date',
        label: 'Дате создания - сначала старые',
        value: {
          sort: 'created_at',
          order: 'asc',
        },
      },
      {
        type: 'date',
        label: 'Дате создания - сначала новые',
        value: {
          sort: 'created_at',
          order: 'desc',
        },
      },
    ],
    statusOptions: [
      {
        label: 'Все',
        value: 'all'
      },
      {
        label: 'Новое',
        value: 'pending'
      },
      {
        label: 'Проверяется',
        value: 'reviewing'
      },
      {
        label: 'Получено',
        value: 'checking'
      },
      {
        label: 'Готово',
        value: 'done'
      },
      {
        label: 'Отклонено',
        value: 'canceled'
      }
    ],
    status: null
  }),
  computed: {
    activeSortOption() {
      return this.sortOptions.find((option) => option.value === this.activeSort)
    },
  },
  methods: {
    onDateToSelected(val) {
      this.filters.dateTo = new Date(val).toISOString()
    },
    onDateFromSelected(val) {
      this.filters.dateFrom = new Date(val).toISOString()
    },
    applyFilters() {
      const activefilters = Object.entries({ ...this.filters, ...(this.sort && { ...this.sort.value }), ...(this.status && { status: this.status.value}) }).reduce((obj, [key, val]) => {
        if (!!val) {
          obj[key] = val
        }
        return obj
      }, {})
      if (!Object.keys(activefilters).length) return

      this.$router.push({ query: { ...activefilters, page: 1 } }).catch(() => {})
    },
    resetFilters() {
      const activefilters = Object.entries({ ...this.filters, ...(this.sort && { ...this.sort.value }), ...(this.status && { status: this.status.value}) }).reduce((obj, [key, val]) => {
        obj[key] = undefined
        return obj
      }, {})
      this.sort = null
      this.status = null
      Object.assign(this.filters, filters)
      if (!Object.values(activefilters).length) return

      this.$router.push({ query: { ...activefilters, page: 1 } }).catch(() => {})
    },
    appendQueryParam(key, value) {
      this.$router.push({ query: { ...this.$route.query, [key]: value } })
    },
    toggleOrder() {
      this.activeOrder = this.activeOrder === 'asc' ? 'desc' : 'asc'
    },
    setActiveSort(sort) {
      if (this.activeSortOption.type === sort.value) {
        this.toggleOrder()
        return
      }
      // this.activeSort = sort
    },
  },
  mounted() {
    Object.keys(this.$route.query).forEach((key) => {
      if (key in this.filters) {
        this.filters[key] = this.$route.query[key]
      } else if (key in this.sortOptions[0].value) {
        const currentSort = this.sortOptions.find((option) => option.value.sort === this.$route.query.sort && option.value.order === this.$route.query.order)
        this.sort = currentSort
      } else if (key === 'status') {
        this.status = this.statusOptions.find(option => option.value === this.$route.query[key])
      }
    })
  },
}
</script>
<style scoped>
.tasks-filter {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  display: grid;
  gap: 2rem;
}

.tasks-filter__buttons {
  display: flex;
  gap: 1rem;
}

.tasks-filter__field {
  margin-bottom: 10px;
  display: grid;
}

.tasks-filter__field-dates {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.tasks-filter__field-dates >>> .form-field {
  width: 100%;
}
.tasks-filter__sort {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  align-items: center;
  flex-direction: column;
}

.tasks-filter__sort > div {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.tasks-filter__sort-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 1rem; */
  width: 100%;
  max-width: 400px;
  margin-left: auto;
}
.tasks-filter__sort-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tasks-filter__sort-item:first-child {
  padding-left: 15px;
}

.tasks-filter__sort-item:disabled {
  opacity: 0.9;
}

.tasks-filter__sort-item svg {
  visibility: hidden;
}

.tasks-filter__sort-item--active {
  font-weight: bold;
}

.tasks-filter__sort-item--active svg {
  visibility: visible;
}
.tasks-filter__sort-item--desc svg {
  transform: rotate(180deg);
}

@media (min-width: 550px) {
  .tasks-filter__sort {
    /* max-width: 400px; */
    /* margin-left: auto; */
  }

  .tasks-filter__sort-container {
    /* max-width: 400px; */
  }
}

@media (min-width: 700px) {
  .tasks-filter__container {
    display: flex;
    flex-wrap: wrap;
  }
  .tasks-filter label {
    margin: 0;
  }

  .tasks-filter__field {
    padding: 0 15px 0 0;
    margin-bottom: 20px;
  }

  .tasks-filter__field-dates {
    flex-direction: row;
  }

  .tasks-filter__field-dates >>> .form-field {
    width: 150px;
  }
}
</style>
