<template>
  <router-link :to="{ query: { ...$route.query, taskId: task.id}}" class="tasks-list__item">
    <div class="tasks-list__user-container">
      <div
        data-label="UID"
        class="tasks-list__user"
        :class="{
          'tasks-list__user--no-reviewer': !task.reviewer,
        }"
      >
        {{ task.uid }}
      </div>
      <div class="tasks-list__user-controls">
        <!-- <div><b>{{ item.tasksToShow.length }}</b> {{ item.tasksToShow.length | pluralize('задание', 'задания', 'заданий') }}</div> -->
        <!-- <div>
          <button type="button" v-if="show === false" @click.stop="show = true" title="Развернуть">
            <font-awesome-icon icon="chevron-down" />
          </button>
          <button type="button" v-if="show !== false" @click.stop="show = false" title="Свернуть">
            <font-awesome-icon icon="chevron-up" />
          </button>
        </div> -->
        <div data-label="Дата создания">{{ createdAt }}</div>
        <div data-label="Дата последнего изменения">{{ updatedAt }}</div>
        <div data-label="Ответственный">{{ task.reviewer || 'Не назначен' }}</div>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  props: ['task'],
  data: () => ({}),
  computed: {
    updatedAt() {
      return new Intl.DateTimeFormat('ru-RU', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(this.task.updated_at || this.task.created_at))
    },
    createdAt() {
      return new Intl.DateTimeFormat('ru-RU', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(this.task.created_at))
    }
  },
  methods: {},
}
</script>
<style scoped>
.tasks-list__item {
  background: none;
  outline: none;
  padding: 0;
  display: block;
  border: none;
  color: inherit !important;
  text-align: left;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 4px solid #f8f8f8;
}

.tasks-list__item--active {
  border-color: var(--main-color);
}

.tasks-list__user-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.tasks-list__user {
  font-weight: 400;
  font-size: 22px;
  padding: 2px 15px 4px;
  cursor: pointer;
  flex: 1;
  min-width: 200px;
  /* max-width: 300px; */
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tasks-list__user::before {
  content: attr(data-label);
  display: block;
  color: #999;
  font-size: 12px;
}

.tasks-list__user--no-reviewer {
  font-weight: 600;
}

.tasks-list__item--active .tasks-list__user {
  background-color: var(--main-color);
  color: #fff;
}

.tasks-list__user-controls {
  max-width: 600px;
  min-width: 100px;
  width: 100%;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  /* justify-content: space-between; */
  align-items: center;
  padding: 6px 15px;
  font-size: 16px;
  /* background-color: #fafafa; */
}

.tasks-list__user-controls > div::before {
  content: attr(data-label);
  display: block;
  color: #999;
  font-size: 12px;
}

.tasks-list__item--active .tasks-list__user-controls {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  background-color: var(--main-color);
  color: #fff;
}

.tasks-list__user-controls button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
}

@media (min-width: 800px) {
  .tasks-list__user-container {
    flex-direction: row;
  }

  .tasks-list__user-controls {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .tasks-list__item:not(:first-child) .tasks-list__user::before {
    display: none;
  }
  .tasks-list__item:not(:first-child) .tasks-list__user-controls > div::before {
    display: none;
  }
  .tasks-list__user::before, .tasks-list__user-controls > div::before {
    margin-bottom: 10px;
  }
}
</style>
