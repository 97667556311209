import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  /**
   * @typedef {Object} GetOptions
   * @property {string} uid - Номер телефона.
   * @property {number} offset - Смещение пагинации.
   * @property {number} limit - Лимит пагинации.
   * @property {string} sort - Сортировка по created_at или updated_at
   * @property {string} date_from - Поиск по дате
   * @property {string} date_to - Поиск по дате
   * @property {string} order - Направление сортировки asc/desc
   * @property {string} reviewer - Uid ответственного
   * @property {string} status - Статус задачи
   */
  /**
   * Получение списка заданий
   * @param {GetOptions} options
   * @returns {Array}
   */
  get(store, options = {}) {
    Vue.$logger.debug('admin-task | Getting all tasks')

    const urlQueryParams = new URLSearchParams()

    if (options.uid) {
      urlQueryParams.set('uid', options.uid)
    }

    if (options.offset) {
      urlQueryParams.set('offset', options.offset)
    }

    if (options.limit) {
      urlQueryParams.set('limit', options.limit)
    }

    if (options.sort) {
      urlQueryParams.set('sort', options.sort)
    }

    if (options.order) {
      urlQueryParams.set('order', options.order)
    }

    if (options.reviewer !== '' && options.reviewer !== undefined) {
      urlQueryParams.set('reviewer', options.reviewer)
    }

    if (options.date_from) {
      urlQueryParams.set('date_from', options.date_from)
    }

    if (options.date_to) {
      urlQueryParams.set('date_to', options.date_to)
    }

    if (options.status) {
      urlQueryParams.set('status', options.status)
    }

    let ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/tasks-v2'

    if (urlQueryParams.toString()) {
      ACTION_URL = ACTION_URL + '?' + urlQueryParams.toString()
    }
    // const ACTION_URL = '/pseudo-api/tasks.json'

    return axios
      .get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      })
      .catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-task-v2 | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('tasks_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-task-v2', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
}
