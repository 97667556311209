<template>
  <div class="modal micromodal-slide" id="admin-task-details" aria-hidden="true" v-if="task !== null">
    <div class="modal__overlay" tabindex="-1" data-micromodal-close>
      <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
        <div class="modal__wrapper">
          <header class="modal__header">
            <h2 class="modal__title">
              Информация о задании <span v-if="!error">{{ task.id }}</span>
            </h2>
            <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>
          </header>
          <main class="modal__content">
            <div class="alert alert--danger" v-if="error">
              <p v-html="error"></p>
            </div>
            <div v-if="!error" class="user-details">
              <div class="tasks-list__task">
                <div class="tasks-list__task-container">
                  <div class="tasks-list__task-content">
                    <div class="tasks-list__task-type"><b>Пользователь</b>: {{ task.uid}}</div>
                    <div class="tasks-list__task-type"><b>Тип</b>: {{ getTypesLabels(task.type) }}</div>
                    <div class="tasks-list__task-status"><b>Статус</b>: {{ getStatusesLabels(task.status) }}</div>
                    <div class="tasks-list__task-date"><b>Дата создания</b>: {{ formateDate(task.created_at) }}</div>
                    <div class="tasks-list__task-date"><b>Дата последнего изменения</b>: {{ formateDate(task.updated_at || task.created_at) }}</div>
                    <div class="tasks-list__task-reviewer"><b>Ответственный</b>: {{ task.reviewer || 'Не назначен' }}</div>
                  </div>
                  <div
                    class="tasks-list__task-controls"
                    :class="{
                      'tasks-list__task-controls--empty': !([EnumTaskType.approvement_request, EnumTaskType.act_request].includes(task.type) && ![EnumTaskStatus.done].includes(task.status)),
                    }"
                  >
                    <button
                      class="tasks-list__task-control tasks-list__task-control--accept"
                      v-if="[EnumTaskStatus.checking, EnumTaskStatus.pending].includes(task.status)"
                      @click="review"
                      :disabled="task.status === EnumTaskStatus.pending"
                    >
                      <font-awesome-icon icon="briefcase" /><span>В работу</span>
                    </button>
                    <button
                      class="tasks-list__task-control tasks-list__task-control--accept"
                      v-if="[EnumTaskStatus.reviewing].includes(task.status)"
                      @click="approve"
                    >
                      <font-awesome-icon icon="check" /><span>Подтвердить</span>
                    </button>
                    <button
                      class="tasks-list__task-control tasks-list__task-control--decline"
                      v-if="task.status === EnumTaskStatus.reviewing"
                      @click="decline"
                    >
                      <font-awesome-icon icon="ban" /><span>Отклонить</span>
                    </button>
                  </div>
                </div>
                <div class="tasks-list__task-data" v-if="typeof task.meta !== 'undefined' || typeof task.meta !== 'undefined' && task.meta.manager_comment">
                  <div v-if="typeof task.meta !== 'undefined'">
                    <h4><b>Данные</b>:</h4>
                    <div class="tasks-list__task-data-items">
                      <div class="tasks-list__task-data-item">
                        <template v-if="task.type === 'approvement_request' && typeof task.meta === 'undefined' || Object.values(task.meta).length === 0">
                          <div><b>Фамилия: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Имя: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Отчество: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Дата рождения: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Контактный телефон: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Email: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Серия паспорта: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Номер паспорта: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Кем выдан: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Когда выдан: </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>Почтовый адрес для отправки (2-НДФЛ): </b><span class="text--transparent text--red">не указано</span></div>
                          <div><b>ИНН (12 цифр): </b><span class="text--transparent text--red">не указано</span></div>
                        </template>
                        <template v-if="task.type === 'approvement_request' && typeof task.meta !== 'undefined' && Object.values(task.meta).length !== 0">
                          <div><b>Фамилия:</b> {{ task.meta.lastName }}</div>
                          <div><b>Имя:</b> {{ task.meta.firstName }}</div>
                          <div><b>Отчество:</b> {{ task.meta.secondName }}</div>
                          <div><b>Дата рождения:</b> {{ task.meta.birthDate }}</div>
                          <div><b>Контактный телефон:</b> {{ task.meta.phone }}</div>
                          <div><b>Email:</b> {{ task.meta.email }}</div>
                          <div><b>Серия паспорта:</b> {{ task.meta.passportSeries }}</div>
                          <div><b>Номер паспорта:</b> {{ task.meta.passportNumber }}</div>
                          <div><b>Кем выдан:</b> {{ task.meta.passportIssuedBy }}</div>
                          <div><b>Когда выдан:</b> {{ task.meta.passportIssuedDate }}</div>
                          <div><b>Почтовый адрес для отправки (2-НДФЛ):</b> {{ task.meta.postAddress }}</div>
                          <div><b>ИНН (12 цифр):</b> {{ task.meta.inn }}</div>
                        </template>
                        <template v-if="task.type === 'act_request' && typeof task.meta === 'undefined' || Object.values(task.meta).length === 0">
                          <div><b>Год:</b> <span class="text--transparent text--red">не указано</span></div>
                          <div><b>№ заказа:</b> <span class="text--transparent text--red">не указано</span></div>
                          <div><b>Сумма:</b> <span class="text--transparent text--red">не указано</span></div>
                        </template>
                        <template v-if="task.type === 'act_request' && typeof task.meta !== 'undefined' && Object.values(task.meta).length !== 0">
                          <div><b>Год:</b> {{ task.meta.year }}</div>
                          <div><b>№ заказа:</b> {{ task.meta.order_id }}</div>
                          <div><b>Сумма:</b> {{ task.meta.sum }}</div>
                        </template>
                        <template v-if="['approvement_request', 'act_request'].includes(task.type) && typeof task.meta !== 'undefined' && typeof task.meta.files !== 'undefined'">
                          <div v-for="(file, idx) in task.meta.files" :key="idx">
                            <a :href="file" target="_blank">{{ file }}</a>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div v-if="task.meta.manager_comment">
                    <h4><b>Комментарий менеджера:</b></h4>
                    <div v-html="task.meta.manager_comment"></div>
                  </div>
                </div>
              </div>

              <div>
                <button 
                  class="toggle-history-btn" 
                  :class="{
                    'toggle-history-btn--active': showHistory
                  }" 
                  @click="showHistory = !showHistory">
                  История
                </button>
                <AdminTaskItemHistoryList :uid="task.uid" :task-id="task.id" v-if="showHistory"/>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Swal from 'sweetalert2'
import MicroModal from 'micromodal'

import adminTask from '../api/admin-task'
import adminUser from '../api/admin-user'

import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'
import { EnumTaskStatus, EnumTaskType, taskTypesLabel, taskStatusesLabel } from 'base/modules/constants'

import AdminTaskItemHistoryList from './AdminTaskItemHistoryList.vue'

export default {
  name: 'AdminTaskItemDetailsModal',
  components: { AdminTaskItemHistoryList },
  data() {
    return {
      task: null,
      error: '',
      showHistory: false,
      EnumTaskStatus,
      EnumTaskType
    }
  },
  computed: {
    taskId: {
      get() {
        return this.$route.query.taskId || null
      },
      set(value) {
        this.appendQueryParam('taskId', value)
      },
    },
  },
  filters: {
    numberFormat: function (number, decimals, decimalPoint, thousandsSeparator) {
      return utils.numberFormat(number, decimals, decimalPoint, thousandsSeparator)
    },
    date: function (date) {
      const dateObj = new Date(date)
      return utils.formatDateTime(dateObj)
    },
  },
  methods: {
    emitRefresh() {
      this.$emit('refresh')
    },
    formateDate(date) {
      if(!date) return
      return new Intl.DateTimeFormat('ru-RU', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date))
    },
    appendQueryParam(key, value) {
      let query = { ...this.$route.query, [key]: value }
      if ([null, undefined].includes(value)) {
        delete query[key]
      }
      this.$router.push({ query }).catch(()=>{})
    },
    showTaskDetails: function () {
      if (!this.taskId) return
      this.fetchTask()
    },
    fetchTask() {
      return adminTask.getById(this.$store, this.taskId).then((response) => {
          this.task = response.data
          this.$nextTick(() => {
            this.showModal()
            if (this.task.type === 'approvement_request') {
              this.fetchUser()
                .then((response) => {
                  this.task.meta = {...this.task.meta, ...response.data.meta }
                })
                .catch((error) => {
                  this.error = appError.getMessage(error.message)
                  this.task = {}
                  Vue.$logger.debug('AdminTaskItemDetails | admin.getTask | ошибка: ', error)
                })
            }
          })
        })
        .catch((error) => {
          this.error = appError.getMessage(error.message)
          this.task = {}
          Vue.$logger.debug('AdminTaskItemDetails | admin.getTask | ошибка: ', error)
          this.$nextTick(() => {
            this.showModal()
          })
        })
    },
    fetchUser() {
      return adminUser.getUser(this.$store, this.task.uid)
    },
    showModal: function () {
      MicroModal.show('admin-task-details', {
        onShow: (modal) => {
          // document.body.style.position = 'fixed'
          document.body.style.width = '100%'
        },
        onClose: (modal) => {
          document.body.style.position = 'initial'
          document.body.style.width = 'auto'
          this.taskId = undefined
          this.task = null
        },
        openTrigger: 'data-micromodal-open',
        closeTrigger: 'data-micromodal-close',
        disableScroll: true,
        disableFocus: false,
        awaitCloseAnimation: false,
        debugMode: true,
      })
    },
    closeModal: function () {
      MicroModal.close('admin-task-details')
    },
    approve() {
      Swal.fire({
        title: 'Подтвердить<br> выполнение задания?',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        confirmButtonClass: 'button button--confirm button--rounded button--large button--margined',
        cancelButtonClass: 'button button--cancel button--rounded button--large button--margined',
        buttonsStyling: false,
      }).then((result) => {
        this.error = ''

        if (result.value) {
          adminTask
            .approve(this.$store, this.task.id)
            .then(() => {
              this.fetchTask()
              this.emitRefresh()
            })
            .catch((error) => {
              this.error = appError.getMessage(error.message)

              Vue.$logger.debug('AdminTasks | adminTask.approve | ошибка: ', error)
            })
        }
      })
    },
    decline() {
      Swal.fire({
        title: 'Отклонить задание?',
        type: 'question',
        input: 'textarea',
        inputPlaceholder: 'Введите комментарий',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        confirmButtonClass: 'button button--confirm button--rounded button--large button--margined',
        cancelButtonClass: 'button button--cancel button--rounded button--large button--margined',
        buttonsStyling: false,
      }).then((result) => {
        this.error = ''

        if (typeof result.value !== 'undefined') {
          adminTask
            .decline(this.$store, this.task.id, result.value)
            .then(() => {
              this.fetchTask()
              this.emitRefresh()
            })
            .catch((error) => {
              this.error = appError.getMessage(error.message)

              Vue.$logger.debug('AdminTasks | adminTask.decline | ошибка: ', error)
            })
        }
      })
    },
    review() {
      Swal.fire({
        title: 'Взять задание в работу?',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        confirmButtonClass: 'button button--confirm button--rounded button--large button--margined',
        cancelButtonClass: 'button button--cancel button--rounded button--large button--margined',
        buttonsStyling: false,
      }).then((result) => {
        this.error = ''

        if (typeof result.value !== 'undefined') {
          adminTask
            .review(this.$store, this.task.id)
            .then(() => {
              this.fetchTask()
              this.emitRefresh()
            })
            .catch((error) => {
              this.error = appError.getMessage(error.message)

              Vue.$logger.debug('AdminTasks | adminTask.review | ошибка: ', error)
            })
        }
      })
    },
    getTypesLabels(typeKey) {
      return taskTypesLabel[typeKey]
    },
    getStatusesLabels(statusKey) {
      return taskStatusesLabel[statusKey]
    },
  },
  created: function () {},
  mounted: function () {
    this.showTaskDetails()
  },
  watch: {
    taskId(val) {
      this.error = ''
      this.showHistory = false
      if (val) {
        this.showTaskDetails()
        return
      }
      this.closeModal()
    },
  },
}
</script>

<style lang="css">
.user-details {
  font-size: 14px;
}

.user-details__tasks {
  margin-bottom: 20px;
}

.user-details__tasks-item {
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 3px solid #f6f6f6;
  padding-top: 2px;
  padding-bottom: 2px;
}

.user-details__tasks-column {
  padding: 5px 15px;
}

.user-details__tasks-column + .user-details__tasks-column {
  border-top: 1px solid #fafafa;
}

.user-details__tasks-column:before {
  content: attr(data-label);
  display: block;
  color: #999;
  font-size: 12px;
}

.user-details__tasks-column > span {
  display: block;
}

.user-details__tasks-column--uid > span {
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2;
}

.user-details__tasks-column--detail > span {
  font-size: 12px;
}

.user-details__tasks-column hr {
  border-bottom: 1px solid #f0f0f0;
  margin: 0.5em 0;
}

.user-details__tasks-item--canceled .user-details__tasks-column span {
  color: #bbb;
}

.user-details__tasks-column span.user-details__tasks-column-empty-detail {
  color: #999;
}

.user-details__tasks-item-status {
  font-size: 12px;
  padding: 2px 0;
}

.user-details__tasks-item-status + .user-details__tasks-item-status {
  border-top: 1px solid #f0f0f0;
}

@media (min-width: 640px) {
  .user-details__tasks {
    display: table;
    width: 100%;
    max-width: 1000px;
    margin-top: 40px;
  }

  .user-details__tasks-item {
    display: table-row;
  }

  .user-details__tasks-item:hover {
    background-color: #f8f8f8;
  }

  .user-details__tasks-item:hover .user-details__tasks-column,
  .user-details__tasks-item:hover + .user-details__tasks-item .user-details__tasks-column {
    border-top-color: var(--main-color);
  }

  .user-details__tasks-item:last-child .user-details__tasks-column {
    border-bottom: 1px solid #fafafa;
  }

  .user-details__tasks-item:last-child:hover .user-details__tasks-column {
    border-bottom-color: var(--main-color);
  }

  .user-details__tasks-item .user-details__tasks-column:before {
    display: none;
  }

  .user-details__tasks-item:first-child .user-details__tasks-column:before {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 110%;
    padding: 0 15px;
  }

  .user-details__tasks-column {
    display: table-cell;
    position: relative;
  }

  .user-details__tasks-column--task-id {
    border-top: 1px solid #fafafa;
  }
}

.tasks-list__task {
  font-size: 15px;
}

.tasks-list__task + .tasks-list__task {
  border-top: 2px solid var(--main-color);
}

.tasks-list__task-content {
  padding: 6px 0;
  flex: 1;
}

.tasks-list__task-controls {
  display: flex;
  align-items: stretch;
  background-color: #fefefe;
  min-height: 44px;
}

.tasks-list__task-controls--empty {
  display: none;
}

.tasks-list__task-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: #fafafa;
  cursor: pointer;
  padding: 10px 0;
  font-size: 16px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    color 0.2s,
    background-color 0.2s;
}

.tasks-list__task-control:only-child {
  width: 100%;
}

.tasks-list__task-control--accept {
  color: #090;
}

.tasks-list__task-control--accept:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.tasks-list__task-control--decline {
  color: #c00;
}

.tasks-list__task-control span {
  color: #333;
  transition: color 0.2s;
  margin-left: 8px;
}

.tasks-list__task-control:hover:not(:disabled) {
  color: #fff;
}

.tasks-list__task-control--accept:hover:not(:disabled) {
  background-color: #090;
  color: #fff;
}

.tasks-list__task-control--decline:hover {
  background-color: #c00;
  color: #fff;
}

.tasks-list__task-control:hover:not(:disabled) span {
  color: #fff;
}

.tasks-list__task-data {
  padding: 5px 0;
  border-top: 1px solid #f8f8f8;
  word-wrap: break-word;
  line-height: 1.35;
}

.tasks-list__task-data > div {
  padding: 10px 0;
}

.tasks-list__user-controls > div {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tasks-list__task-data > div + div {
  border-top: 1px solid #f0f0f0;
}

.tasks-list__task:last-child .tasks-list__task-data {
  border-bottom: none;
}

@media (min-width: 550px) {
  .tasks-list__user-controls {
    display: grid;
  }
}

@media (min-width: 700px) {


  .tasks-list__task + .tasks-list__task {
    border-top-width: 1px;
  }

  .tasks-list__task-controls {
    /* margin-left: auto;
    width: 280px; */
  }

  .tasks-list__task-controls--empty {
    display: flex;
  }

  .tasks-list__task-control {
    flex-flow: column;
    padding: 8px 20px;
  }

  .tasks-list__task-control span {
    margin-left: 0;
  }
}

.tasks-list__task-date,
.tasks-list__task-reviewer {
  display: block;
}

@media (min-width: 768px) {
  .tasks-list__item {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  /* .tasks-list__task-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  } */

  .tasks-list__task-type {
    margin-right: auto;
  }

  .tasks-list__task-status {
    width: 160px;
  }

  /* .tasks-list__task-controls {
    width: 320px;
  } */

  .tasks-list__task-control {
    flex-flow: row;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tasks-list__task-control span {
    margin-left: 8px;
  }
}

.toggle-history-btn {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  text-decoration: underline;
  text-underline-offset: 10%;
  font-size: calc(1.275rem + .3vw);
  margin-bottom: 20px;
}

.toggle-history-btn--active {
  color: var(--main-color);
}
</style>
