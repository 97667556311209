<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-xl-7">
          <h1>Управление заданиями</h1>
        </div>
      </div>
      <div class="alert alert--danger" v-if="error">
        <p v-html="error"></p>
      </div>
      <AdminTasksFilters />
      <div class="tasks-list">
        <template v-if="list.pages_total > 0">
          <AdminTaskItem v-for="task in list.items" :key="task.id" :task="task"/>
        </template>
        <template v-else> Ничего не найдено </template>
      </div>
      <div class="tasks-pagination">
        <Pagination :page="page" :pagesTotal="list.pages_total" :pageSize="perPage" :itemsTotal="list.items_total" :onPageChange="(currentPage) => (page = currentPage)" />
        <select v-model="perPage">
          <option v-for="option in [10, 20, 30, 40]" :key="option" :value="option">{{ option }}</option>
        </select>
      </div>
    </div>
    <AdminTaskItemDetailsModal @refresh="getData"/>
  </main>
</template>

<script>
import Vue from 'vue'
import adminTaskV2 from '../api/admin-task-v2'
import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'

import AdminTaskItem from './AdminTaskItem.vue'
import AdminTasksFilters from './AdminTasksFilters.vue'
import AdminTaskItemDetailsModal from './AdminTaskItemDetailsModal.vue'

import Pagination from './Pagination.vue'

const filters = {
  sort: 'created_at',
  order: 'desc',
  page: 1,
  perPage: 20,
}

export default {
  name: 'TaskManaging',
  components: { AdminTaskItem, AdminTasksFilters, AdminTaskItemDetailsModal, Pagination },
  data() {
    return {
      showAll: false,
      list: {},
      error: '',
      // uid: '',
      sort: filters.sort,
      order: filters.order,
    }
  },
  computed: {
    page: {
      get() {
        return Number(this.$route.query.page) || filters.page
      },
      set(value) {
        this.appendQueryParam('page', value)
      },
    },
    perPage: {
      get() {
        return Number(this.$route.query.perPage) || filters.perPage
      },
      set(value) {
        this.appendQueryParam('perPage', value)
      },
    },
    filtered: function () {
      if (typeof this.list.items === 'undefined') {
        return []
      }
      return this.list.items.filter((item) => {
        if (this.showAll) {
          return item
        }

        return item.status === 'checking'
      })
    },
  },
  filters: {
    pluralize: function (number, one, two, five) {
      return utils.pluralize(number, one, two, five)
    },
  },
  methods: {
    appendQueryParam(key, value) {
      this.$router.push({ query: { ...this.$route.query, [key]: value } }).catch(() => {})
    },
    getData() {
      const limit = this.perPage
      const offset = (this.page - 1) * limit

      const { uid, sort, order, reviewer, dateFrom, dateTo, status } = this.$route.query

      const options = {
        ...(uid && { uid }),
        ...(sort && { sort }),
        ...(order && { order }),
        ...(reviewer && { reviewer: reviewer.toLowerCase() === 'не назначен' ? null : reviewer }),
        ...(dateFrom && { date_from: dateFrom }),
        ...(dateTo && { date_to: dateTo }),
        ...(status !== 'all' && { status }),
        limit,
        offset,
      }
      adminTaskV2
        .get(this.$store, options)
        .then((response) => {
          const data = response.data
          this.list = data
        })
        .catch((error) => {
          this.error = appError.getMessage(error.message)

          Vue.$logger.debug('AdminTasks | adminTask.get | ошибка: ', error)
        })
    },
    afterMounted: function () {},
  },
  created: function () {
    this.getData()
    // this.debouncedGetData = utils.debounce(this.getData, 800)
  },
  mounted: function () {
    this.afterMounted()
  },
  watch: {
    '$route.query': {
      handler(val) {
        if (!!val['taskId']) return
        this.getData()
      }
    },
  },
}
</script>

<style lang="css" scoped>
.tasks-list {
  margin-bottom: 40px;
  display: grid;
  gap: 1.5rem;
}

.tasks-list__task {
  font-size: 15px;
}

.tasks-list__task + .tasks-list__task {
  border-top: 2px solid var(--main-color);
}

.tasks-list__task-content {
  padding: 6px 15px;
  flex: 1;
}

.tasks-list__task-controls {
  display: flex;
  align-items: stretch;
  background-color: #fefefe;
  min-height: 44px;
}

.tasks-list__task-controls--empty {
  display: none;
}

.tasks-list__task-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: #fafafa;
  cursor: pointer;
  padding: 10px 0;
  font-size: 16px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    color 0.2s,
    background-color 0.2s;
}

.tasks-list__task-control:only-child {
  width: 100%;
}

.tasks-list__task-control--accept {
  color: #090;
}

.tasks-list__task-control--decline {
  color: #c00;
}

.tasks-list__task-control span {
  color: #333;
  transition: color 0.2s;
  margin-left: 8px;
}

.tasks-list__task-control:hover {
  color: #fff;
}

.tasks-list__task-control--accept:hover {
  background-color: #090;
  color: #fff;
}

.tasks-list__task-control--decline:hover {
  background-color: #c00;
  color: #fff;
}

.tasks-list__task-control:hover span {
  color: #fff;
}

.tasks-list__task-data {
  padding: 5px 15px;
  border-top: 1px solid #f8f8f8;
  word-wrap: break-word;
  line-height: 1.35;
}

.tasks-list__task-data > div {
  padding: 10px 0;
}

.tasks-list__task-data > div + div {
  border-top: 1px solid #f0f0f0;
}

.tasks-list__task:last-child .tasks-list__task-data {
  border-bottom: none;
}

.tasks-pagination {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media (min-width: 700px) {
  .tasks-list__task-container {
    display: flex;
    flex-direction: row;
  }

  .tasks-list__task + .tasks-list__task {
    border-top-width: 1px;
  }

  .tasks-list__task-controls {
    margin-left: auto;
    width: 280px;
  }

  .tasks-list__task-controls--empty {
    display: flex;
  }

  .tasks-list__task-control {
    flex-flow: column;
    padding: 8px 20px;
  }

  .tasks-list__task-control span {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .tasks-list__item {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .tasks-list__task-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .tasks-list__task-type {
    margin-right: auto;
  }

  .tasks-list__task-status {
    width: 160px;
  }

  .tasks-list__task-controls {
    width: 320px;
  }

  .tasks-list__task-control {
    flex-flow: row;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tasks-list__task-control span {
    margin-left: 8px;
  }
}
</style>

<style>
.text--red {
  color: #900;
}

.text--transparent {
  opacity: 0.6;
}

.tasks-list__task-data-item + .tasks-list__task-data-item {
  margin-top: 4px;
}
</style>
