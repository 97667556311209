<template>

  <div class="task-history">
    <div v-if="error">
        Произошла ошибка при загрузке
    </div>
    <div v-else-if="isLoading">
      Загружаем историю
    </div>
    <div v-else-if="tasks.length">
      <router-link :to="{ query: { ...$route.query, taskId: task.id }}" class="task-history-item" v-for="task in tasks" :key="task.id">
        <div class="task-history-item__container">
          <div data-label="ID">{{ task.id }}</div>
          <div data-label="Тип">{{ getTypesLabels(task.type) }}</div>
          <div data-label="Дата создания">{{ formateDate(task.created_at) }}</div>
          <div data-label="Дата изменения">{{ formateDate(task.updated_at || task.created_at) }}</div>
          <div data-label="Статус">{{ getStatusesLabels(task.status) }}</div>
        </div>
      </router-link>
    </div>
    <div v-else>
      История отсутствует
    </div>
  </div>
</template>
<script>
import adminTaskV2 from '../api/admin-task-v2';

import { EnumTaskStatus, EnumTaskType, taskTypesLabel, taskStatusesLabel } from 'base/modules/constants'

const shortTypeLabels = {
  [EnumTaskType.approvement_request]: 'Документы',
  [EnumTaskType.act_request]: 'Акт',
  [EnumTaskType.user_data_request]: 'Данные пользователя',
}

export default {
  props: ['uid', 'taskId'],
  data: () => ({
    isLoading: false,
    error: false,
    tasks: [],
  }),
  methods: {
    async fetchTasks() {
      try {
        this.isLoading = true;

        const { data: { items } } = await adminTaskV2.get(this.$store, { uid: this.uid, order: 'desc', sort: 'created_at', limit: 1000 });

        if (items?.length) {
          this.tasks = items.filter(item => item.id !== this.taskId).sort(item => [EnumTaskType.user_data_request, EnumTaskType.approvement_request].includes(item.type) && item.status === EnumTaskStatus.done ? -1 : 0 )
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.isLoading = false;
      }
    },
    getTypesLabels(typeKey) {
      return shortTypeLabels[typeKey]
    },
    getStatusesLabels(statusKey) {
      return taskStatusesLabel[statusKey]
    },
    formateDate(date) {
      return new Intl.DateTimeFormat('ru-RU', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date))
    },
  },
  mounted() {
    this.fetchTasks();
  },
}
</script>
<style scoped>
.task-history {
  max-height: 200px;
  overflow-y: auto;
}
.task-history-item {
  font-weight: 400;
  font-size: 14px;
  padding: 4px;
  flex: 1;
  min-width: 200px;
  /* max-width: 300px; */
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: inherit;
  display: block;
}

.task-history-item:hover {
  background: #fafafa;
  color: inherit;
}

.task-history-item:not(:first-child) {
  margin-top: 10px;
}


.task-history-item__container {
  display: grid;
  justify-items: left;
  align-items: center;
  gap: 10px;
}

.task-history-item__container > div::before {
  content: attr(data-label);
  display: block;
  color: #999;
  font-size: 12px;
}

@media (min-width: 600px) {

  .task-history-item__container {
    grid-template-columns: 20px 145px 1fr 1fr 1fr;
    gap: 20px;
  }
  .task-history-item:not(:first-child) .task-history-item__container > div::before {
    display: none;
  }
}
</style>