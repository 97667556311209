<template>
  <main>
    <div class="container">
      <h1>Список пользователей</h1>
      <ThisComponent
        :list-params="[
          {
            name: 'active_tasks_count',
            label: 'Задания',
            filter: false,
            type: 'number'
          }
        ]"
      ></ThisComponent>
      <br>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AdminUsersList'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--admin-users-list')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>
